export default {
  clearIndicator: () => ({}),
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({}),
  input: () => ({}),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  noOptionsMessage: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
};
