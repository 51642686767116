import './annotations/all';
import './templating/all';
import './plugins/all';
import './dashboard/all';
import './playlist/all';
import './panel/all';
import './org/all';
import './admin';
import './alerting/NotificationsEditCtrl';
import './alerting/NotificationsListCtrl';
import './manage-dashboards';
import './teams/CreateTeamCtrl';
import './profile/all';
